// .doc-print {
//   width: 21cm;
//   min-height: 29.7cm;
//   padding: 2cm;
//   background-color: white;
//   margin: auto;
//   overflow: visible;
//   position: relative;
// }


// .page-break {
//   page-break-after: always;
// }

// @media print {
//   @page{
//     size: A4;
//     margin: 0;
//   }

//   body{
//     margin: 0;
//   }
//   .doc-print{
//     width: 100%;
//     height: auto;
//     overflow: hidden;
//     padding: 1.25cm;
    
//   }

//   .page-break{
//     display: block;
//     page-break-before: always;
//   }
// }

textarea, textarea:focus
{
  outline-width: 0;
  outline: none;
}
::-webkit-resizer {
  border: 0px solid black;
  background-image: url("../../../../assets/images/resize.png");
  // box-shadow: 0 0 5px 5px blue;
  background-repeat: no-repeat, repeat;
  // outline: 0px solid #00617F;
  padding:20px;
  height:40;
  width:40;
  z-Index:10
}
.mostly-customized-scrollbar::-webkit-scrollbar {
  width: 14px;
}
.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
}
.mostly-customized-scrollbar::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color:lightgrey
}

textarea::-webkit-scrollbar {
  width: 22px;
  }

textarea::-webkit-filter{
   blur:0.000001px;
}
.doc-print{
  padding: 20px;
  width: 100%;
}

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 100px;
  width: 100%;
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.isResizable {
  border: 1px solid black;
  overflow: auto;
  resize: vertical;
  width: '100%';
  height: 120px;
  min-width: 120px;
  min-height: 90px;
  // max-height: 300px;
}

.custom-textarea {
  resize:both;
  display: block;
  overflow: hidden;
  resize: none;
  line-height: 1.5;
  font-size: 14;
  font-weight: normal;
  text-align: left;
  hyphens: none;
  border: 1px solid #3a3a3a;
  margin: 10px;
  padding: 6px;
}
.input {
  outline:none;
 }
 

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: rgba(0, 0, 0, 0.65);
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  color: white;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #ff6e40;
  box-shadow: 0 0 13px 3px #ff6e40;
}

@media print{
  body{
    print-color-adjust: exact !important;
  }
  .doc-print{
    padding: 0;
  }
}