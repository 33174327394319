.trial-location-table{
    table, th, td {
        // border: 1px solid #E0E0E0;
        border-collapse: collapse;
      }
      th, td {
        padding: 5px;
        text-align: left;
      }
      .trialName-scroll{
          width: 50%;
          white-space: nowrap;
      }
}
