.top-card-cond {
    .MuiCardActionArea-root:focus {
        background-color: transparent;
        color: #5fb9ea;
        border-bottom: #5fb9ea solid 3px;

        .MuiTypography-root {
            color: #5fb9ea;
        }
    }


}

// .top-card{
//     background-color: transparent;
//     color: #5fb9ea;
//     border-bottom: #5fb9ea solid 3px;
//     border-radius: 0px;


//     .MuiTypography-root{
//         color: #5fb9ea;
//     }
// }
.top-card-main{
    background-color: transparent;
    color: #5fb9ea;
    border-bottom: #5fb9ea solid 3px;
    border-radius: 0px;
    .MuiTypography-root {
        color: #5fb9ea;
    }
}

// .css-b62m3t-container >  .css-hsd4ry-control{
//     min-width: 300px;
// }

.acc-react-select>.css-j0ozid {
    display: flex;
    flex-direction: column;

    label {
        margin-right: auto;
    }
}

.table-row>.css-m282x2-MuiTableCell-root {
    line-height: 0.5rem;
}