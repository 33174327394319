.top-card{
    position: relative;
    overflow: hidden;
    &::before{
        content: '""';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 50%;
        background: linear-gradient(to bottom right, blue, darkblue);
        z-index: 0;
    }
}


.top-card-content{
    z-index: 1;
    position: relative;
    padding: 20px;
}


.top-card-contacts{
    background: linear-gradient(102deg, #00617f 64%, rgba(137, 211, 41, 1) 64%, #10384f 0%);
}

.top-card-activities{
    background: linear-gradient(102deg, #2b6636 64%, rgb(137, 211, 41) 64%, #004422 0%);
}

.top-card-tracking{
    background: linear-gradient(102deg, #624963 64%, rgb(137, 211, 41) 64%, #443247 0%);
}

.top-card-payout{
    background: linear-gradient(102deg, #de0043 64%, rgb(137, 211, 41) 64%, #d3054b 0%);
}