.sso-login-option {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #DCE8EB;
    line-height: 0.1em;
    margin: 10px 0 20px;
    // padding: 5px 0px 5px 0px;
    span {
      background: #FFFFFF;
      padding: 0 10px;
      color: #CCD6D9;

    }
  }