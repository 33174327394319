.dateinputs-forecast > .MuiBox-root{
    width: 100%;
    margin-left:8px
}
.input.middle:focus {
    outline-width: 0;
}


.datestyle{
    height: 42px;
    width:96%;
    padding-left: 7px;
    border-width: 1px;
    border-color:lightgrey;
    border-radius: 5px;
    border-style: ridge;
    padding-right: 5px;
    font-weight: 400;
    color: black;
    font-style: normal;
    font-size: 11.5px;
    font-family: 'Helvetica Neue';
    outline:none;
    margin-left:0px,
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: '📅';
    width: 15px;
    height: 15px;
    border-width: 1px;
    border-color:'lightgrey';
    // content: attr(placeholder) !important;

}
// input[type="date"]:before{
//     color:lightgray;
//     content:attr(placeholder);
// }
// input[type="date"].full:before {
//     color:black;
//     content:""!important;
// }